/* --------------------------------------------------Dashboard css--------------------------------------------- */
.homeContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.homeChildOne {
    width: 30%;
    min-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

.homeImageContainer {
    width: 60%;
    height: 95vh;
    background-image: url('../Images/valmiki.png');
    /* background-image: url('https://github.com/Pannakbhushana/karm-kand/assets/112654188/2d0d7d09-344b-4409-97e1-33f45e787433'); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
}

.accordionContainer{
    /* border: 1px solid black; */
    padding-right: 5%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
}

.accordionChild:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}


/* -----------------------------------AboutMe Css---------------------------- */
.aboutContainer {
    width: 100%;
    min-height: 95vh;
    display: flex;
    justify-content: space-around;
}

.aboutChildOne {
    width: 30%;
    max-height: 95vh;
}

.aboutChildTwo {
    width: 50%;
    min-height: 95vh;
    padding-top: 3%;
}


@media (max-width: 768px) {
    .homeContainer {
        width: 100%;
        display: flex;
    }

    .homeChildOne {
        width: 100%;
        height: 90vh;
        background-image: url('../Images/valmiki.png');
        background-size: 100%;
        background-position: right center;
        background-repeat: no-repeat;
    }

    .homeImageContainer {
        display: none;
    }



    .aboutChildOne {
        width: 40%;
        max-height: 95vh;
    }

    .aboutChildTwo {
        width: 60%;
        min-height: 95vh;
        padding-top: 8%;
        padding-right: 3%;
    }
}





@media only screen and (max-width: 600px) {

    .aboutContainer {
        width: 100%;
        min-height: 95vh;
        display: block;
    }

    .aboutChildOne {
        width: 50%;
        height: 300px;
        margin-left: 25%;
    }
    .aboutChildTwo {
        width: 100%;
        min-height: 95vh;
        padding: 3%;
    }

}