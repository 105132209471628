.coloredTheme{
  text-align: center;
  background: linear-gradient(to right, #d56b01, #FFFF00);
  color: #333333;
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
}

.darkTheme{
  text-align: center;
  background: linear-gradient(to right, #020202, #444444);
  color: #F5F5F5;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none; 
}